
import BarChartIcon from '@material-ui/icons/BarChart';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';

export default [
  {
    subheader: 'Dashboard MSGPERU',
    items: [
      {
        title: 'Empresas',
        href: '/company',
        icon: HomeIcon,
        items: [
          {
            title: 'Empresas',
            href: '/company'
          },
          {
            title: 'Clientes',
            href: '/client'
          },
          {
            title: 'Agregar Empresa',
            href: '/add-company'
          },
          {
            title: 'Agredar Cliente',
            href: '/add-client'
          },
        ]
      },
      {
        title: 'Cotizaciones',
        href: '/cotizacion',
        icon: BarChartIcon,
        items: [
          {
            title: 'Cotizaciones (Ultimas 30)',
            href: '/cotizacion30/all'
          },
          {
            title: 'Cotizaciones (Todas)',
            href: '/cotizacion/all'
          },
          {
            title: 'Nueva cotizacion',
            href: '/new-cotizacion'
          },
          {
            title: 'OT (Ultimas 30)',
            href: '/odtlast'
          },
          {
            title: 'OT (Todas)',
            href: '/odt'
          },
        ]
      },
      {
        title: 'Documentos MSG',
        href: '/documents',
        icon: FolderIcon,
        items: [
          {
            title: 'Documentos',
            href: '/documents'
          },
          {
            title: 'Agregar documento',
            href: '/add-document'
          },
          {
            title: 'Descargar certificados',
            href: '/exel'
          },
          {
            title: 'Test descarga 2',
            href: '/exel2'
          }
        ]
      }
    ]
  },
];
