
import BarChartIcon from '@material-ui/icons/BarChart';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';

export default [
  {
    subheader: 'Dashboard MSGPERU',
    items: [
      {
        title: 'Documentos MSG',
        href: '/documents',
        icon: FolderIcon,
        items: [
          {
            title: 'Documentos',
            href: '/documents'
          }
        ]
      }
    ]
  },
];
