/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';

const routes=[
  [
    {
      path: '/',
      exact: true,
      component: () => <Redirect to="/documents" />
    },
    {
      path: '/errors',
      component: ErrorLayout,
      routes: [
        {
          path: '/errors/error-401',
          exact: true,
          component: lazy(() => import('src/views/Error401'))
        },
        {
          path: '/errors/error-404',
          exact: true,
          component: lazy(() => import('src/views/Error404'))
        },
        {
          path: '/errors/error-500',
          exact: true,
          component: lazy(() => import('src/views/Error500'))
        },
        {
          component: () => <Redirect to="/errors/error-404" />
        }
      ]
    },
    {
      route: '*',
      component: DashboardLayout,
      routes: [
        {
          path: '/cotizacion',
          exact: true,
          component: lazy(() => import('src/views/CotizacionList'))
        },
        {
          path: '/documents',
          exact: true,
          component: lazy(() => import('src/views/Documents'))
        },
        {
          component: () => <Redirect to="/errors/error-404" />
        }
      ]
    }
  ],
  [
    {
      path: '/',
      exact: true,
      component: () => <Redirect to="/company" />
    },
    {
      path: '/errors',
      component: ErrorLayout,
      routes: [
        {
          path: '/errors/error-401',
          exact: true,
          component: lazy(() => import('src/views/Error401'))
        },
        {
          path: '/errors/error-404',
          exact: true,
          component: lazy(() => import('src/views/Error404'))
        },
        {
          path: '/errors/error-500',
          exact: true,
          component: lazy(() => import('src/views/Error500'))
        },
        {
          component: () => <Redirect to="/errors/error-404" />
        }
      ]
    },
    {
      route: '*',
      component: DashboardLayout,
      routes: [
        {
          path: '/company',
          exact: true,
          component: lazy(() => import('src/views/Company'))
        },
        {
          path: '/client',
          exact: true,
          component: lazy(() => import('src/views/Users'))
        },
        {
          path: '/add-company',
          exact: true,
          component: lazy(() => import('src/views/AddCompany'))
        },
        {
          path: '/add-client',
          exact: true,
          component: lazy(() => import('src/views/AddUser'))
        },
        {
          path: '/edit-company/:companyid',
          exact: true,
          component: lazy(() => import('src/views/EditCompany'))
        },
        {
          path: '/edit-client/:clientid',
          exact: true,
          component: lazy(() => import('src/views/EditUser'))
        },
        {
          path: '/cotizacion/:tab',
          exact: true,
          component: lazy(() => import('src/views/CotizacionList'))
        },
        {
          path: '/cotizacion30/:tab',
          exact: true,
          component: lazy(() => import('src/views/CotizacionLastList'))
        },
        {
          path: '/new-cotizacion',
          exact: true,
          component: lazy(() => import('src/views/AddCotizacion'))
        },
        {
          path: '/quotation/:quotationid',
          exact: true,
          component: lazy(() => import('src/views/CotizacionInfo'))
        },
        {
          path: '/ot/:quotationid',
          exact: true,
          component: lazy(() => import('src/views/OtInfo'))
        },
        {
          path: '/add-item',
          exact: true,//component: 
          component:lazy(() => import('src/views/AddItem'))
        },
        {
          path: '/update-item',
          exact: true,//component: 
          component:lazy(() => import('src/views/UpdateItem'))
        },
        {
          path: '/update-cotizacion/:quotationid',
          exact: true,//component: 
          component:lazy(() => import('src/views/UpdateCotizacion'))
        },
        {
          path: '/update-component',
          exact: true,//component: 
          component:lazy(() => import('src/views/UpdateComponent'))
        },
        {
          path: '/exel',
          exact: true,//component: 
          component:lazy(() => import('src/views/Exel'))
        },
        {
          path: '/exel2',
          exact: true,//component: 
          component:lazy(() => import('src/views/Exel2'))
        },
        {
          path: '/new-odt',
          exact: true,//component: 
          component:lazy(() => import('src/views/NewOdt'))
        },
        {
          path: '/odt',
          exact: true,
          component: lazy(() => import('src/views/OdtList'))
        },
        {
          path: '/odtlast',
          exact: true,
          component: lazy(() => import('src/views/OdtListLast'))
        },
        {
          path: '/pdf/:quotationid',
          exact: true,//component: 
          component:lazy(() => import('src/views/PdfQuotation'))
        },
        {
          path: '/pdf/odt/:quotationid',
          exact: true,//component: 
          component:lazy(() => import('src/views/PdfOdt'))
        },
        {
          path: '/pdf/carta/:quotationid',
          exact: true,//component: 
          component:lazy(() => import('src/views/PdfCarta'))
        },
        {
          path: '/add-document',
          exact: true,
          component: lazy(() => import('src/views/AddDocument'))
        },
        {
          path: '/documents',
          exact: true,
          component: lazy(() => import('src/views/Documents'))
        },
        {
          component: () => <Redirect to="/errors/error-404" />
        }
      ]
    }
  ],
  [
    {
      path: '/',
      component: AuthLayout,
      routes: [
        {
          path: '/auth/login',
          exact: true,
          component: lazy(() => import('src/views/Login'))
        },
        {
          path: '/auth/register',
          exact: true,
          component: lazy(() => import('src/views/Register'))
        },
        {
          component: () => <Redirect to="/auth/login" />
        }
      ]
    }
  ]
]
export default routes
